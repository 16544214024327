import React, { useEffect, useState } from "react"
import SEO from "../../seo"
import {
  URL_EMAIL,
  URL_HOTMART_CURSO_PRODUTIVIDADE,
  URL_INSTA_FLYNOW,
  URL_WHATSAPP,
} from "../../../constants"
import { getQueryParam } from "../../../utils/query"
import { getTextCupom, formatToMoney } from "../../../utils/cupom"
import styled from "styled-components"
import colors from "../../../colors"
import { FaInstagram, FaWhatsapp } from "react-icons/fa"
import { MdMailOutline } from "react-icons/md"
import { Description, Row, TitleMain } from "../../Ebooks"
import FAQ from "../../FAQ"
import { isAfter, parseISO } from "date-fns"
const primary = colors.primary
const degradeBackground =
  "linear-gradient(297deg, rgba(170,32,255,1) 0%, rgba(115,87,225,1) 30%, rgba(32,26,56,1) 100%)"

const imageBackground = require("./background.png")
const ebookProdutividade = require("../../../assets/images/ebookProdutividade.png")

export const H1 = styled.h1`
  text-align: center;
  font-size: 2.7rem;
`
export const Contact = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: white;
`
const Space = styled.div`
  margin-top: 0.5rem;
`
export const ItemContact = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: white;
`

const Highlight = styled.span`
  color: ${({ color }) => color || primary};
`

export const H2 = styled.h2`
  font-size: 2rem;
  text-align: center;
  color: ${primary};
`

export const P = styled.p`
  font-size: 1.1rem;
  color: ${colors.regular};
`

const Button = styled.a`
  display: block;
  text-align: center;
  padding: 18px 50px;
  color: white;
  background: ${primary};
  border-radius: 40px;
  text-transform: uppercase;
  font-family: "Poppins-Bold";
  cursor: pointer;
  transition: all 0.4s linear;
  &:hover {
    opacity: 0.9;
  }
  width: 500px;
  text-decoration: none;
  @media (max-width: 600px) {
    width: 100%;
  }
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url(${imageBackground});
  background-repeat: no-repeat;
  padding: 2rem;
  background-size: cover;

  .video-copy {
    width: 680px;
    height: 340px;

    @media (max-width: 768px) {
      width: 360px;
      height: 180px;
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: center;
    align-items: center;
    h1 {
      text-shadow: 3px 1px 9px rgba(0, 0, 0, 0.45);
    }
    @media (max-width: 600px) {
      width: 100%;
      h1 {
        font-size: 2rem;
      }
      p {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }
`

export const Section = styled.section`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 15%;
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  ${({ background }) =>
    background && `background: ${background};`}

  @media (max-width: 768px) {
    padding: 2rem 10%;
    input {
      width: 100%;
    }
  }

  .icon {
    font-size: 10rem;
    color: ${colors.textStrong};
    cursor: pointer;
    transition: all 0.4s linear;
  }
  .icon:hover {
    color: ${colors.primary2};
  }
  input {
    padding: 15px;
    border: 5px solid ${colors.primary};
    border-radius: 12px;
    color: ${colors.primary};
    margin-bottom: 20px;
    font-size: 1.1rem;
    width: 80%;
    min-width: 280px;
    max-width: 97%;
  }
  input:focus {
    outline: none !important;
    border-color: ${colors.primary};
    box-shadow: 0 0 10px ${colors.primary};
  }

  .button-waiting-list {
    padding: 15px 50px;
    background: ${colors.green};
    border: none;
    border-radius: 15px;
    cursor: pointer;
    color: ${colors.white};
    font-size: 1.1rem;
    font-family: "Poppins-Bold";
  }
  .button-waiting-list:hover {
    transition: all 0.4s linear;
    box-shadow: 0 0 10px ${colors.green};
  }
  a {
    text-decoration: none;
  }
`

export const contact = [
  {
    link: URL_WHATSAPP,
    img: <FaWhatsapp className="icon" />,
    description: "31 999107753",
  },
  {
    link: URL_EMAIL,
    img: <MdMailOutline className="icon" />,
    description: "support@appflynow.com",
  },
  {
    link: URL_INSTA_FLYNOW,
    img: <FaInstagram className="icon" />,
    description: "@appflynow",
  },
]

const IconAndLabel = ({ icon, label }) => {
  return (
    <div
      style={{
        margin: 15,
        width: 300,
        padding: 25,
        borderRadius: 15,
        border: "1px solid #eee",
        boxShadow: "0 0 10px #ccc",
      }}
    >
      <H2>{icon}</H2>
      <P style={{ textAlign: "center" }}>{label}</P>
    </div>
  )
}

const FlexWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin: 30px 0;
`

export default function ProdutividadeA() {
  const [cupom, setCupom] = useState(null)
  const [production] = useState(isAfter(new Date(),parseISO('2024-08-05')))
  const [utm, setUtm] = useState("")
  const [email, setEmail] = useState("")
  const [test] = useState(new Date().getTime() % 2 === 0 ? "A" : "B")
  const [response, setResponse] = useState({ loading: false })
  const vslA = "https://www.youtube-nocookie.com/embed/tmI6X3h0Clc?autoplay=1"
  const vslB = "https://www.youtube-nocookie.com/embed/BcQqsvg6cAc?autoplay=1"

  // const production = true
  function getUrlCheckout() {
    let url = URL_HOTMART_CURSO_PRODUTIVIDADE
    if (cupom) {
      url = url.concat("?offDiscount=" + cupom)
    }
    if (utm) {
      const prefix = url.includes("?") ? "&" : "?"
      url = url.concat(prefix + "sck=" + utm)
    }
    return url
  }

  const sendEvent = event => {
    if (window) {
      if (window.fbq) window.fbq("track", event)
      if (window.gtag) window.gtag("event", event)
    }
  }

  useEffect(() => {
    sendEvent("view_course_produtividade")
    setCupom(getQueryParam("cupom"))
    setUtm(getQueryParam("utm_source"))
  }, [])

  function getInfo() {
    const discontPercentage = cupom
      ? Number(cupom.replace(/\D/g, "") || "0")
      : 0
    const price = 199.9
    return {
      URL_CHECKOUT: getUrlCheckout(),
      oldPrice: "249,90",
      price: formatToMoney(price),
      newPrice: cupom
        ? formatToMoney(price * (1 - discontPercentage / 100))
        : formatToMoney(price),
      textCupom: cupom
        ? getTextCupom(cupom) ||
          (discontPercentage
            ? `${discontPercentage}% DE DESCONTO! APROVEITE! 🔥`
            : null)
        : null,
      discontPercentage,
    }
  }
  const {
    URL_CHECKOUT,
    price,
    newPrice,
    oldPrice,
    textCupom,
    discontPercentage,
  } = getInfo()

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  const onSubmit = () => {
    if (!email) {
      return alert("Informe o seu email")
    }
    if (!validateEmail(email)) {
      return alert("Informe um email válido")
    }
    sendEvent("click_waiting_list_course_produtividade")
    setResponse({ loading: true })
    fetch("https://api.appflynow.com/waiting-list-course-productivity", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        test,
        cupom,
        utm_source: getQueryParam("utm_source"),
        utm_medium: getQueryParam("utm_medium"),
        utm_campaign: getQueryParam("utm_campaign"),
      }),
    })
      .then(res => {
        if (res.status === 200) {
          setResponse({ status: res.status, loading: false })
          alert(
            "Pronto! Te enviaremos um e-mail com um link promocional quando o curso estiver disponível!"
          )
        } else {
          res.json().then(data => {
            setResponse({
              loading: false,
              status: res.status,
              message: data.message,
            })
            alert(data.message)
          })
        }
      })
      .catch(err => {
        setResponse({ loading: false, message: err.message, status: 400 })
      })
  }

  const onCheckout = () => {
    sendEvent("click_checkout_course_produtividade")
  }

  return (
    <>
      <SEO
        title="Curso de Produtividade | Domine a arte da produtividade"
        pageId="course_produtividade"
        description="Descubra como ser mais produtivo e gerenciar melhor seu tempo para alcançar os seus objetivos - sem lista interminável de tarefas e com muito mais tempo livre para fazer o que quiser."
        imageUrl={require("../../../assets/images/cursoProdutividade.png")}
      />
      <Main>
        <div className="main-content">
          <TitleMain center color="#fff">
            Cansado de <Highlight>procrastinar</Highlight> <br />a vida dos seus{" "}
            <Highlight>sonhos</Highlight> ?
          </TitleMain>
          <Description color="#ddd" style={{ marginBottom: 15 }}>
            Descubra como <b>ser mais produtivo</b> e{" "}
            <b>gerenciar melhor seu tempo</b> para{" "}
            <b>alcançar os seus objetivos</b> -{" "}
            <b>sem lista interminável de tarefas</b> e com muito mais{" "}
            <b>tempo livre</b> para fazer o que quiser.
          </Description>
          {textCupom && (
            <h4
              style={{
                background: colors.red,
                color: "#fff",
                textAlign: "center",
                padding: 5,
                marginTop: 10,
                marginRight: 10,
                marginBottom: 20,
              }}
            >
              {textCupom}
            </h4>
          )}
          <iframe
            className="video-copy"
            src={vslA}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            auto
          ></iframe>
          <br />
          <Button
            href={"#checkout"}
            onClick={onCheckout}
            style={{ marginTop: 15 }}
          >
            QUERO SER PRODUTIVO
          </Button>
        </div>
      </Main>

      <Section backgroundColor={colors.surface}>
        <H2>Se você está cansado de...</H2>
        <br />
        <FlexWrap>
          <IconAndLabel
            icon={"🥱"}
            label={
              <>
                Viver <b>procrastinando</b> <br /> e <b>desorganizado</b>
              </>
            }
          />
          <IconAndLabel
            icon={"😞"}
            label={
              <>
                Não conseguir <b>evoluir</b>
                <br /> pessoal e profissinalmente
              </>
            }
          />
          <IconAndLabel
            icon={"😤"}
            label={
              <>
                Ver as <b>horas do dia </b>
                <br />
                escorrendo pelos seus dedos
              </>
            }
          />
          <IconAndLabel
            icon={"🥵"}
            label={
              <>
                Não conseguir{" "}
                <b>
                  terminar
                  <br /> nada
                </b>{" "}
                que você começa
              </>
            }
          />
          <IconAndLabel
            icon={"😵‍💫"}
            label={
              <>
                Lidar com <b>distrações</b> e <br /> <b>falta de foco</b>{" "}
                constantes
              </>
            }
          />
        </FlexWrap>
      </Section>

      <Section>
        <H2>E quer...</H2>
        <br />
        <FlexWrap>
          <IconAndLabel
            icon={"🚀"}
            label={
              <>
                Ter uma rotina mais
                <br /> <b>organizada</b> e <b>produtiva</b>
              </>
            }
          />
          <IconAndLabel
            icon={"🎯"}
            label={
              <>
                <b>Evoluir</b> e conquistar <br /> seus <b>sonhos</b>
              </>
            }
          />
          <IconAndLabel
            icon={"🤩"}
            label={
              <>
                Gerenciar melhor seu <b>tempo</b> <br /> e ainda ter{" "}
                <b>tempo livre</b>
              </>
            }
          />
          <IconAndLabel
            icon={"💪🏻"}
            label={
              <>
                Ter mais <b>constância</b> em <br /> seus projetos e objetivos
              </>
            }
          />
          <IconAndLabel
            icon={"🧠"}
            label={
              <>
                Eliminar as <b>distrações</b> e <br /> ter <b>mais energia</b>
              </>
            }
          />
        </FlexWrap>

        <P>
          O curso <b>Domine a Arte da Produtividade</b> é perfeito para você! 🤩
        </P>
        <br />
        <Button href={"#checkout"} onClick={onCheckout}>
          QUERO DOMINAR A PRODUTIVIDADE
        </Button>
        <br />
      </Section>

      <Section backgroundColor={colors.surface}>
        <H2>Tudo o que você precisa para...</H2>
        <br />
        <FlexWrap>
          <IconAndLabel
            icon={"🚀"}
            label={
              <>
                Elevar sua <b>produtividade</b>
                <br /> com <b>qualidade de vida</b>
              </>
            }
          />
          <IconAndLabel
            icon={"🎯"}
            label={
              <>
                Conquistar suas <b>metas</b>
                <br /> e <b>objetivos</b>
              </>
            }
          />
          <IconAndLabel
            icon={"🤩"}
            label={
              <>
                Transformar sua vida e <br /> viver sua <b>melhor versão</b>
              </>
            }
          />
        </FlexWrap>

        <br />
      </Section>

      <Section>
        <H2>Além disso, você ainda recebe de bônus...</H2>
        <br />
        <img
          src={ebookProdutividade}
          alt="ebook-produtividade"
          style={{ height: 300, marginBottom: 20, marginTop: 10 }}
        />
        <h3>🔥 E-book Guia Definitivo para a Produtividade</h3>
        <br />
        <P>
          Adquirindo nosso curso, você ganha como bônus o nosso e-book{" "}
          <b>Guia Definitivo para a Produtividade</b>, que contém:
          <br />
          <br />
          <li>
            <b>23 técnicas</b> para ser mais produtivo e{" "}
            <b>alcançar seus objetivos</b>.
          </li>
          <li>
            <b>+6 bônus</b> exclusivos em PDF
          </li>
        </P>
        <br />
      </Section>

      <FAQ
        color={"#444"}
        background={degradeBackground}
        title="O que você vai aprender? 🧠"
        data={[
          {
            title: "MÓDULO 1 - FUNDAMENTOS",
            text: (
              <>
                <ul>
                  <li>O que é ser produtivo?</li>
                  <li>Por que devemos ser mais produtivos?</li>
                  <li>Principais mitos da produtividade</li>
                </ul>
              </>
            ),
          },
          {
            title: "MÓDULO 2 - DEFINA METAS",
            text: (
              <>
                <ul>
                  <li>Como criar metas</li>
                  <li>Ferramenta para criar e mensurar metas</li>
                  <li>Propósito</li>
                </ul>
              </>
            ),
          },
          {
            title: "MÓDULO 3 - PLANEJE",
            text: (
              <>
                <ul>
                  <li>Como fazer um planejamento eficiente</li>
                  <li>Organização e rotina</li>
                  <li>Ferramenta para fazer planejamentos</li>
                </ul>
              </>
            ),
          },
          {
            title: "MÓDULO 4 - PRIORIZE",
            text: (
              <>
                <ul>
                  <li>Tríade do Tempo</li>
                  <li>A única coisa</li>
                  <li>Essencialismo</li>
                  <li>Princípio de Pareto</li>
                </ul>
              </>
            ),
          },
          {
            title: "MÓDULO 5 - EXECUTE",
            text: (
              <>
                <ul>
                  <li>Técnica Pomodoro</li>
                  <li>Método GTD</li>
                  <li>Time Blocking</li>
                  <li>Ambiente</li>
                  <li>Dicas Extras</li>
                </ul>
              </>
            ),
          },
          {
            title: "MÓDULO 6 - MAXIMIZE",
            text: (
              <>
                <ul>
                  <li>Motivação</li>
                  <li>Como ter mais energia</li>
                  <li>Como ter constância</li>
                  <li>Aprimore</li>
                </ul>
              </>
            ),
          },
        ]}
      />

      <Section id="checkout">
        {production ? (
          <>
          <H2>Partiu dominar a Produtividade? 💪🏻🎯</H2>
          <br />
          <P>
            Prepare-se <b>para desbloquear seu verdadeiro potencial </b>com o
            nosso curso e conquistar seus sonhos e objetivos.
            <br />
            <br />E o melhor: por um preço muito menor do que o{" "}
            <b>valor que ele pode te oferecer </b>. Afinal, coisas como liberdade,
            saúde e qualidade de vida são coisas que o dinheiro não paga!
            <br />
          </P>
          <h3>
            <s>DE R$ {oldPrice}</s>
          </h3>
          {price !== newPrice && (
            <>
              <h2>POR <s>R$ {price}</s></h2>
              <h4 style={{ background: '#dd0000', color: '#fff', textAlign: 'center', margin: 5, padding: 5 }}>
                + {discontPercentage}% OFF
              </h4>
            </>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: "4px solid " + primary,
              alignItems: "center",
              color: primary,
              padding: 7,
              marginTop: 5
            }}
          >
            <h6>POR APENAS 12x DE </h6>
            <h1 style={{ margin: 10 }}> R$ {formatToMoney((Number(newPrice.replace(',','.'))*1.1973)/12)} </h1>
            <h6> ou {newPrice} à vista </h6>
          </div>
          <br />
        <Button href={URL_CHECKOUT} onClick={onCheckout}>QUERO GARANTIR MEU CURSO!</Button>
          </>
        ) : (
          <>
            <H2>Garantir desconto de lançamento </H2>
            <br />
            <P
              style={{
                background: degradeBackground,
                color: "white",
                padding: 30,
                fontWeight: "bold",
                borderRadius: 8,
              }}
            >
              Inscreva-se na lista de espera para garantir um DESCONTO SUPER
              ESPECIAL quando o curso for lançado. 👇
            </P>
            <br />
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Informe o seu melhor e-mail"
            />
            <br />
            <Button onClick={onSubmit}>
              {!response.loading && !response.status
                ? "Quero receber uma oferta exclusiva"
                : response.loading
                ? "Carregando..."
                : response.status === 200
                ? "Sucesso!"
                : response.message}
            </Button>
          </>
        )}

        <br />
      </Section>

      <FAQ
        color={"#444"}
        background={degradeBackground}
        data={[
          {
            title: "Como é o formato do curso?",
            text:
              "Aulas gravadas, que você pode assistir quando e onde quiser. :)",
          },
          {
            title: "Qual é a duração total do curso?",
            text: "O curso contém 6 módulos (total de 22 aulas).",
          },
          {
            title: "E se eu tiver dúvidas?",
            text:
              "Você poderá tirar suas dúvidas dentro da própria plataforma de aulas.",
          },
          {
            title: "Quais são as opções de pagamento?",
            text:
              "Cartão de crédito (parcelado em até 12x), PIX ou saldo Hotmart. Usuários premium dos nossos apps têm 40% de desconto!",
          },
          {
            title: "E se eu não gostar do curso?",
            text:
              "Caso você não fique completamente satisfeito com o curso, basta nos avisar dentro de sete dias após a compra e nós devolveremos seu dinheiro. Simples assim!",
          },
        ]}
      />

      <Section backgroundColor={colors.surface}>
        <H2>Garantia incondicional de 7 dias ✅</H2>
        <br />
        <img
          src={require("../../../assets/images/garantia7dias.png")}
          alt="garantia-7-dias"
          style={{ height: 250 }}
        />
        <div>
          <P>
            Como sabemos que você irá amar nosso curso, te oferecemos uma
            garantia incondicional de 7 dias.
          </P>
          <P>
            Ou seja, caso você esteja insatisfeito(a) com ele, basta nos avisar
            em até sete dias após a compra que devolveremos seu dinheiro.
            Simples assim!
          </P>
        </div>
        <br />
      </Section>

      <Section>
        <H2>Alguma dúvida?</H2>
        <br />
        <P>Se tiver qualquer dúvida entre em contato conosco!</P>
        <Contact>
          {contact.map(i => (
            <ItemContact key={i.link}>
              <a
                href={i.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textAlign: "center" }}
              >
                {i.img}
                <h4 style={{ color: colors.textStrong }}>{i.description}</h4>
              </a>
            </ItemContact>
          ))}
        </Contact>
        <br />
      </Section>
    </>
  )
}
